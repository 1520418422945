<template>
  <div class="row">
    <div class="col-12 col-lg-3 g">
      <div class="card card-body text-center">
        <img :src="require('@/assets/images/device.png')" class="c g" style="width: 100px;" alt="">
        <h3>{{ stats.devices }}</h3>
        {{ lang('devices') }}
      </div>
    </div>
    <div class="col-12 col-lg-3 g">
      <div class="card card-body text-center">
        <img :src="require('@/assets/images/users.png')" class="c g" style="width: 100px;" alt="">
        <h3>{{ stats.users }}</h3>
        {{ lang('users') }}
      </div>
    </div>
    <div class="col-12 col-lg-3 g">
      <div class="card card-body text-center">
        <img :src="require('@/assets/images/fingerprint.png')" class="c g" style="width: 100px;" alt="">
        <h3>{{ stats.fingerprints }}</h3>
        {{ lang('fingerprints') }}
      </div>
    </div>
    <div class="col-12 col-lg-3 g">
      <div class="card card-body text-center">
        <img :src="require('@/assets/images/upload.png')" class="c g" style="width: 100px;" alt="">
        <h3>{{ stats.reports_today }}</h3>
        {{ lang('reports-uploaded-today') }}
      </div>
    </div>
    <div class="col-12 g">
      <div class="card">
        <div class="card-header">
          <h5>
            <i class="fa fa-upload"></i>
            {{ lang('reports-uploaded-today') }}
          </h5>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-sm table-bordered table-hover table-striped">
              <thead>
                <th>
                  {{ lang("number") }}
                </th>
                <th>
                  {{ lang("serial-number") }}
                </th>
                <th>
                  {{ lang("time") }}
                </th>
              </thead>
              <tbody v-if="stats.reports">
                <tr v-for="r in stats.reports" :key="r.number">
                  <td>
                    {{ r.id }}
                  </td>
                  <td>
                    {{ r.serial_number }}
                  </td>
                  <td>
                    {{ r.time }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data(){
    return {
      user: JSON.parse(localStorage.getItem('user')),
      stats: {}
    }
  },
  created(){
    var g = this;
    $.post(api + '/user/web/stats', {
        jwt: this.user.jwt
    }).then(function(r){
      g.stats = r.response
    })
  }
}
</script>

<style>

</style>
